import { registerApplication, start } from 'single-spa'


const vueApps = [
  'culture',
  'medicine',
  'news',
  'votings',
  'events',
  'projects',
  'service-area',
  'goods-and-services',
  'control-tko',
  'cleaning',
  'rent',
  'alerts',
  'statistics',
  'nto',
  'excavation',
  'road-events',
  'initiatives',
  'user-profile',
]

const vueAdminApps = [
  'culture',
  'medicine',
  'gis-zkh',
  'service-area',
  'control-tko',
  'cleaning',
  'excavation',
  'rent',
  'lighting',
  'kumi',
  'communications',
  'hydrants',
  'constructions',
  'arm',
  'alerts',
  'video-control',
  'full-video',
  'yards',
  'nto',
  'requests',
  'mobile-stations',
  'warning-points',
  'heating',
  'road-events',
  'covid',
  'electric',
  'elevators',
  'economy',
]

if (window.__CONFIG__.cityName === 'Волгодонск') {
  vueApps.push('transport')
}

const noLayoutRoutes = [ 'login', 'login2', 'logout' ]

const matchApp = pathname => app =>
  new RegExp(`^/${app}`).test(pathname)

const matchAdminApp = pathname => app =>
  new RegExp(`^/admin/${app}`).test(pathname)

const isNotVue = pathname => !vueApps.some(matchApp(pathname)) && !vueAdminApps.some(matchAdminApp(pathname))
const isNoLayout = pathname => noLayoutRoutes.some(route => new RegExp(`^/${route}`).test(pathname))
const isAdminLayout = pathname => /^\/admin/.test(pathname)

const appsConfig = [
  {
    appName: '@smartsarov/admin-nav',
    elementId: 'header',
    activityFn: ({ pathname }) => isAdminLayout(pathname),
  },
  {
    appName: '@smartsarov/common-data',
    elementId: 'commonData',
    activityFn: () => true,
  },
  {
    appName: '@smartsarov/header',
    elementId: 'header',
    activityFn: ({ pathname }) =>
      !isAdminLayout(pathname) && !isNoLayout(pathname),
  },
  {
    appName: '@smartsarov/footer',
    elementId: 'footer',
    activityFn: ({ pathname }) =>
      !isAdminLayout(pathname) && !isNoLayout(pathname),
  },
  {
    appName: '@smartsarov/react-app',
    activityFn: ({ pathname }) => isNotVue(pathname),
  },
  ...vueApps.map(appName => ({
    appName,
    activityFn: ({ pathname }) =>
      !isAdminLayout(pathname) && matchApp(pathname)(appName),
  })),
  ...vueAdminApps.map(appName => ({
    appName: appName + '-admin',
    activityFn: ({ pathname }) =>
      isAdminLayout(pathname) && matchAdminApp(pathname)(appName),
  })),
]

appsConfig.forEach(({ appName, elementId, activityFn }) => {
  registerApplication(appName, () => System.import(appName), activityFn, {
    domElement: document.getElementById(elementId || 'root'),
  })
})

start()
